import $j from 'jquery';
import SalientDebugger from '../salient-debugger/salientDebugger';

let surnameClass;
let nameClass;
let inputSurname;
let inputName;
let positionVertical;
const INPUTMAXLENGTH = 20;

function updateCreditcard(name, surname) {
    let nameInChars = name.split('');
    name = name.replace(/ä/g,'ae');
    name = name.replace(/ü/g,'ue');
    name = name.replace(/ö/g,'oe');
    name = name.replace(/Ä/g,'AE');
    name = name.replace(/Ü/g,'UE');
    name = name.replace(/Ö/g,'OE');
    name = name.replace(/ß/g,'ss');

    surname = surname.replace(/ä/g,'ae');
    surname = surname.replace(/ü/g,'ue');
    surname = surname.replace(/ö/g,'oe');
    surname = surname.replace(/Ä/g,'AE');
    surname = surname.replace(/Ü/g,'UE');
    surname = surname.replace(/Ö/g,'OE');

    if (name.length < INPUTMAXLENGTH && surname.length < INPUTMAXLENGTH) {
        SalientDebugger.messageInfo("name < 20 & surname < 20");
        return {name: name+ ' ', surname: surname}
    } else if ((1 + surname.length) < INPUTMAXLENGTH) {
        SalientDebugger.messageInfo("shortened name & surname < 20");
        return {name: nameInChars[0] + '. ', surname: surname}
    } else if (surname.split('-').length === 2 && (2 + surname.split('-')[0].length) < INPUTMAXLENGTH) {
        SalientDebugger.messageInfo("split surname ");
        return {name: nameInChars[0] + '. ' + surname.split('-')[0] + '-', surname: surname.split('-')[1]}
    } else {
        let shortenedName = name;
        let shortenedSurname = surname;

        if (shortenedName.length > INPUTMAXLENGTH) {
            shortenedName = shortenedName.substr(0, INPUTMAXLENGTH);
        }

        if (shortenedSurname.length > INPUTMAXLENGTH) {
            shortenedSurname = shortenedSurname.substr(0, INPUTMAXLENGTH);
        }

        SalientDebugger.messageInfo("substring name and surname");
        return {name: shortenedName + ' ', surname: shortenedSurname}
    }
}

export class CreditCard {
    constructor(surnameInputClass, nameInputClass) {
        surnameClass = surnameInputClass;
        nameClass = nameInputClass;
        inputSurname = $j("." + surnameClass).data("source");
        inputName = $j("." + nameClass).data("source");
        positionVertical = $j('.of-kartemitpraegung').data('praegung_offset_bottom');

        try {
            if($j("#" + inputSurname)[0] === undefined) {
                if ($j("#" + inputSurname)[0].value === '') {
                    $j('.' + surnameClass)[0].innerText = $j("." + surnameClass).data("defaultvalue");
                } else {
                    $j('.' + surnameClass)[0].innerText = $j("#" + inputSurname)[0].value;
                }
            }
        } catch (e) {
            new Error();
        }

        try {
            if($j("#" + inputName)[0] === undefined) {
                if ($j("#" + inputName)[0].value === '') {
                    $j('.' + nameClass)[0].innerText = $j("." + nameClass).data("defaultvalue");
                } else {
                    $j('.' + nameClass)[0].innerText = $j("#" + inputName)[0].value;
                }
            }
        } catch (e) {
            new Error();
        }

        if (positionVertical !== undefined) {
            $j('.of-praegezeile').css({
                bottom: positionVertical
            });
        }
    }

    listenForInput() {
        $j('#' + inputSurname).keyup(function () {
            let inputStringName = $j("#" + inputName)[0].value;
            let inputStringSurname = $j("#" + inputSurname)[0].value;

            let creditcardFormat = updateCreditcard(inputStringSurname, inputStringName);

            SalientDebugger.messageLog(creditcardFormat);

            $j('.' + surnameClass)[0].innerText = creditcardFormat.name;
            $j('.' + nameClass)[0].innerText = creditcardFormat.surname;
        });

        $j('#' + inputName).keyup(function () {
            let inputStringName = $j("#" + inputName)[0].value;
            let inputStringSurname = $j("#" + inputSurname)[0].value;
            let creditcardFormat = updateCreditcard(inputStringSurname, inputStringName);

            SalientDebugger.messageLog(creditcardFormat);

            $j('.' + surnameClass)[0].innerText = creditcardFormat.name;
            $j('.' + nameClass)[0].innerText = creditcardFormat.surname;
        });
    }
}
