/* eslint-disable */
import CMJSContext from "notification";
/* eslint-enable */
export class NotificationCenter {
    constructor(notificationName, callback) {
        this.addNewObserver(notificationName, callback);
    }

    addNewObserver(notificationName, callback) {
        CMJSContext.NotificationCenter.defaultCenter.addObserver(null, callback, notificationName, null);
    }
}
