/* global digitalData */
// total geklauter Code von mercedes-creditcard.de... Danke! :-)

import $j from "jquery";

/*
function getCookie(cname) {
    for (var name = cname + "=", ca = document.cookie.split(";"), i = 0; i < ca.length; i++) {
        for (var c = ca[i]; " " == c.charAt(0);)
            c = c.substring(1);
        if (0 == c.indexOf(name))
            return c.substring(name.length, c.length)
    }
    return ""
}
*/

// Consent Event
// This event is triggered when the consent has been accepted via the Cookie Banner. (Upon being triggered an automatic "consentInitiated" Pageload event will be set - this does not need to be implemented by the developers!)						Consent analogue to main site?

export function initCookies () {
	let accept = window.localStorage.getItem('firstCookieAccept');

/*
	const acceptByCookie = getCookie('firstCookieAccept');

	if(acceptByCookie) {
		if( !accept ) {
			window.localStorage.setItem('firstCookieAccept', true);
			digitalData.event.push({
				eventInfo: {
					eventAction: "consent",
					eventName: "accepted",
					eventType: "click"
				}
			});
			accept = true;
		}
	}
*/
	if (accept) {
		// yay
	} else {
		const cookieLayer = $j("#of-cookiebanner");
//		const inner = $j('#of-cookiebanner-content');
		const close = $j('#of-cookie-accept');

    // cookieLayer.css({ height: 'auto' }).show();
    cookieLayer.show();

		close.on("click", function () {
			window.localStorage.setItem('firstCookieAccept', true);

/*
			var theDate = new Date,
				oneYearLater = new Date(theDate.getTime() + 31536e6),
				expiryDate = oneYearLater.toGMTString();
			var host = document.location.host;
			host = host.split(":")[0];  // um den eventuell vorhandenen Port wegzuschmeissen
			if(host == "localhost") {
				document.cookie = 'firstCookieAccept=true; expires=' + expiryDate + ';path=/;';
			}
			else {
				var domain = host.split(".").slice(1).join("."); // domain ohne host
				document.cookie = 'firstCookieAccept=true; expires=' + expiryDate + ';path=/;domain=.'+domain+';';
			}
*/
		//	cookieLayer.css({ height: inner.outerHeight() + 'px' });
			cookieLayer.hide();

			digitalData.event.push({
				eventInfo: {
					eventAction: "consent",
					eventName: "accepted",
					eventType: "click"
				}
			})
		});
	}
}
