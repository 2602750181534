import $j from "jquery";

let toggleItems = (id) => {
    let toggleIds = $j(`#${id}`).attr("data-toggleids")
    if(typeof toggleIds !== typeof undefined && toggleIds !== false) {
        let showIds = $j(`#${id}`).attr("data-toggleids").split(',');
        $j(showIds).each((i, item) => {
            $j(`#${item}`).toggleClass("d-block");
            $j(`#${item}`).toggleClass("d-none");
        });
    }
};

export function toggleMobileLandingpage (id) {
    toggleItems(id);

    $j(`#${id} .of-mobile-intro-button button`).click(() => {
        toggleItems(id);
        $j(".of-fragengruppe-mobileintro").toggle();
    });
}
