import $j from "jquery";

const BREAKPOINTS = {
    xs: 0,
    sm: 570,
    md: 750,
    lg: 990,
    xl: 1280
}

const detectBreakpoint = () => {
    const windowWidth = window.innerWidth;
    switch (true) {
        case windowWidth < BREAKPOINTS.sm:
            return "xs";
        case windowWidth < BREAKPOINTS.md:
            return "sm";
        case windowWidth < BREAKPOINTS.lg:
            return "md";
        case windowWidth < BREAKPOINTS.xl:
            return "lg";
        default:
            return "xl";
    }
}

const initCarousel = () => {
    const activeBreakpoint = detectBreakpoint();
    const responsiveCarouselElements = document.querySelectorAll(".carousel-" + activeBreakpoint);

    responsiveCarouselElements.forEach(carousel => {
        const innerCarousel = carousel.querySelector(".carousel-inner");

        [...innerCarousel.children].forEach((node, index) => {
            if (index === 0) {
                node.classList.add("active");
            }

            node.classList.add("carousel-item");
        })

        $j(carousel).on('slide.bs.carousel', (event) => {
            const indicators = carousel.querySelectorAll(".carousel-indicators")

            indicators.forEach(indicatorSet => {
                indicatorSet.children.item(event.from).classList.remove("active")
                indicatorSet.children.item(event.to).classList.add("active")
            })
        })

        const additioanlIndicators = carousel.querySelectorAll(".carousel-indicators .additional-indicator:first-child, .carousel-indicators .indicator:first-child")

        additioanlIndicators.forEach(indicator => {
            indicator.classList.add("active");
        })

        carousel.classList.add("carousel")
        carousel.classList.add("js-init")

        $j(carousel).carousel({"ride": "carousel", });
    })
}
export const responsiveCarousel = () => {
    let timeout = null;

    initCarousel();

    window.addEventListener('resize', () => {
        if (timeout) {
            window.clearTimeout(timeout)
        }

        timeout = window.setTimeout(() => {
            const carousels = document.querySelectorAll(".carousel.js-init");

            carousels.forEach(carousel => {
                carousel.classList.remove("carousel")
                carousel.classList.remove("js-init")
                $j(carousel).off('slide.bs.carousel')

                const items = carousel.querySelectorAll(".carousel-item")
                items.forEach(item => {
                    item.classList.remove("carousel-item");
                    item.classList.remove("active");
                })

                const activeIndicators = carousel.querySelectorAll(".carousel-indicators .active")

                activeIndicators.forEach(indicator => {
                    indicator.classList.remove("active");
                })

                $j(carousel).carousel("dispose");
            });

            initCarousel()
        }, 200)
    });
}
