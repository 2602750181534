import $j from "jquery";

export class Nuggets {
    constructor(id) {
        this.createNuggetContainer(id);
        this.registerToggleListener(id);
    }

    registerToggleListener(id) {
        $j("#" + id + " .of-kachelhuelle").click((e) => {
            e.preventDefault();
            let kachelContainer = $j(e.target).parents(".of-kachelhuelle");
            let content = kachelContainer.find(".of-option-nugget").text();
            let nuggetContainer = kachelContainer.attr("data-col-number");

            // is checkbox checked
            if ($j(kachelContainer).find(".of-kachel-control-input")[0].checked) {
                /*
                 *      uncheck checkbox
                 *      hide nugget container
                 *      hide arrow
                 */
                $j(kachelContainer).find(".of-kachel-control-input")[0].checked = false;
                $j(kachelContainer.nextAll(".js-nugget-container")[0]).fadeOut();
                $j(kachelContainer.nextAll(".arrow-" + nuggetContainer)[0]).css("visibility", "hidden");
            } else {
                /*
                 *      uncheck other checkboxes which are children of the ID
                 *      set checkbox checked
                 *      set text and arrow
                 */
                $j("#" + id + " .of-kachel-control-input").each((i, item) => {
                    item.checked = false;
                });

                if($j(kachelContainer).find(".of-option-nugget").length === 0) {
                    $j(kachelContainer).nextAll(".js-nugget-container").fadeOut();
                    $j(kachelContainer.nextAll(".js-arrow")).css("visibility", "hidden");
                }

                $j(kachelContainer).find(".of-kachel-control-input")[0].checked = true;

                if (content !== "") {
                    $j(kachelContainer.nextAll(".js-nugget-container")[0]).fadeIn();
                    $j(kachelContainer.nextAll(".js-nugget-container").find(".nugget-wrapper")[0]).text(content).fadeIn();
                    $j(".js-arrow").css("visibility", "hidden");
                    $j(kachelContainer.nextAll(".arrow-" + nuggetContainer)[0]).css("visibility", "visible");
                }
            }
        });
    }

    insertAfter(newNode, referenceNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }

    createNuggetContainer(id) {
        let elements = $j("#" + id + " .of-kachelhuelle");
        let elementCount = elements.length;

        let nuggetContainer = document.createElement("div");
        nuggetContainer.classList.add("col-12");
        nuggetContainer.classList.add("js-nugget-container");
        nuggetContainer.setAttribute("style", "display: none;");
        let nuggetWrapper = document.createElement("div");
        nuggetWrapper.classList.add("nugget-wrapper");
        nuggetContainer.appendChild(nuggetWrapper);

        let nuggetArray = [];

        elements.each((i, item) => {
            if (item.classList.contains("col-4")) {
                let elementNumber = i + 1;
                item.dataset.colNumber = elementNumber;

                if (elementNumber === elementCount && elementCount % 3) {
                    item.classList.add("insertOffset");

                    let newRow = document.createElement("div");
                    newRow.classList.add("w-100");
                    nuggetArray.unshift(newRow);

                    let arrowElement = document.createElement("div");
                    arrowElement.classList.add(`col-4`);
                    arrowElement.classList.add("js-arrow");
                    arrowElement.classList.add(`arrow-${i + 1}`);
                    nuggetArray.push(arrowElement);
                    nuggetArray.push(nuggetContainer.cloneNode(true));
                    nuggetArray.forEach((element, index) => {
                        if (index === 0) {
                            this.insertAfter(element, item);
                        } else {
                            this.insertAfter(element, nuggetArray[index - 1]);
                        }
                    });
                    nuggetArray = [];

                } else if (elementNumber % 3 === 0) {
                    item.classList.add("insertNugget");
                    let arrowElement = document.createElement("div");
                    arrowElement.classList.add("col-4");
                    arrowElement.classList.add("js-arrow");
                    arrowElement.classList.add(`arrow-${i + 1}`);
                    nuggetArray.push(arrowElement);

                    nuggetArray.push(nuggetContainer.cloneNode(true));

                    nuggetArray.forEach((element, index) => {
                        if (index === 0) {
                            this.insertAfter(element, item);
                        } else {
                            this.insertAfter(element, nuggetArray[index - 1]);
                        }
                    });
                    nuggetArray = [];

                } else {
                    let arrowElement = document.createElement("div");
                    arrowElement.classList.add("col-4");
                    arrowElement.classList.add("js-arrow");
                    arrowElement.classList.add(`arrow-${i + 1}`);
                    nuggetArray.push(arrowElement);
                }
            }
        });
    }
}
