import $j from 'jquery';

export class Accordion {
    constructor (triggerClass, containerClass) {
        this.registerClickListener(triggerClass, containerClass);
    }

    registerClickListener (triggerClass, containerClass) {
        $j(`.${triggerClass}`).click((e) => {
            if(e.target.classList.contains(triggerClass)) {
                $j(e.target).find('.of-bearbeiten').toggleClass("d-flex");
                $j(e.target).next($j(`.${containerClass}`)).slideToggle();
            } else {
                $j(e.target).parents(`.${triggerClass}`).next($j(`.${containerClass}`)).slideToggle();
                $j(e.target).parents(`.${triggerClass}`).find('.of-bearbeiten').toggleClass("d-flex");
            }
        });
    }
}
