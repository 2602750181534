import $j from "jquery";

export function preventToggleOnOpenLightbox (toggleClass) {
    let toggleBtnArray = $j(`.${toggleClass} .custom-control-label`);
    toggleBtnArray.each((index, item) => {
        $j(item).click((e) => {
            if($j(".modal.show").length !== 0) {
                if (e.target.nodeName === 'A' && e.target.getAttribute('target') === '_blank') {
                    e.preventDefault();
                    window.open(e.target.href, '_blank');
                } else {
                    e.preventDefault();
                }
            }

            if($j(e.target).hasClass("modal")) {
                e.preventDefault();
            }
        })
    });

}
