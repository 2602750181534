let instance = null;
let debuggerOptions = {
    logError: false,
    logInfo: false,
    logMessage: false,
    logWarnings: false
};

let Console = console;
class SalientDebugger {
    constructor() {
        if (!instance) {
            instance = this;
        }

        return instance;
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    getOptions () {
        return debuggerOptions;
    }

    turnOnDebugger( options ) {
        if(this.getCookie("debug") === 'true') {
            options.logError = true;
            options.logInfo = true;
            options.logMessage = true;
            options.logWarnings = true;
        } else if (this.getCookie("debug") === 'false') {
            options.logError = false;
            options.logInfo = false;
            options.logMessage = false;
            options.logWarnings = false;
        }

        if(this.getCookie("error") === 'true') {
            options.logError = true;
        }

        if(this.getCookie("warning") === 'true') {
            options.logWarnings = true;
        }

        if(this.getCookie("info") === 'true') {
            options.logInfo = true;
        }

        if(this.getCookie("msg") === 'true') {
            options.logMessage = true;
        }

        debuggerOptions.logError = options.logError;
        debuggerOptions.logInfo= options.logInfo;
        debuggerOptions.logMessage = options.logMessage;
        debuggerOptions.logWarnings = options.logWarnings;

        if (options.logError === true) {
            Console.log("Salient Debugger Errors turned on");
        }

        if (options.logInfo === true) {
            Console.log("Salient Debugger Infos turned on");
        }

        if (options.logMessage === true) {
            Console.log("Salient Debugger Messages turned on");
        }

        if (options.logWarnings === true) {
            Console.log("Salient Debugger Warnings turned on");
        }
    }

    messageError (stringMessage, verbose = false) {
        if (debuggerOptions.logError) {
            if (verbose) {
                Console.error("%c Salient Debugger says: Error", 'color: tomato; font-weight: bold;');
            }

            Console.error( stringMessage );
        }
    }

    messageLog (stringMessage, verbose = false) {
        if (debuggerOptions.logMessage) {
            if (verbose) {
                Console.log("%c Salient Debugger says: Log", 'color: turquoise; font-weight: bold;');
            }
            Console.log(stringMessage);
        }
    }

    messageInfo (stringMessage, verbose = false) {
        if (debuggerOptions.logInfo) {
            if(verbose) {
                Console.info("%c Salient Debugger says: Info", 'color: lightslategray; font-weight: bold;');
            }

            Console.info( stringMessage );
        }
    }

    messageWarning (stringMessage, verbose = false) {
        if (debuggerOptions.logWarnings) {
            if (verbose) {
                Console.warn("%c Salient Debugger says: Warning", 'color: yellow; font-weight: bold;');
            }

            Console.warn( stringMessage );
        }
    }
}

export default new SalientDebugger();
