export const animateLayoutcontainer = () => {
    const containerList = document.querySelectorAll(".of-layoutcontainer")

    const options = {
        root: null,
        rootMargin: "10px",
        threshold: 0.001
    }

    const addAnimationClass = (entries, observer) => {

        entries.forEach(entry => {
            if (entry.isIntersecting) {

                entry.target.addEventListener('animationend', () => {
                    entry.target.classList.remove("animate-entry");
                })

                entry.target.classList.add("animate-entry");
                observer.unobserve(entry.target)
            }
        })
    }

    const observer = new IntersectionObserver(addAnimationClass, options)

    containerList.forEach(container => {
        observer.observe(container)
    })
}
