// JS
import $j from 'jquery';
import 'popper.js';
import "bootstrap";
import "tempusdominus-bootstrap-4";

import { CreditCard } from '../js/feature-card/feature-card';
import { initCookies} from "../js/cookie-banner/cookie-banner";
import SalientDebugger from '../js/salient-debugger/salientDebugger';
import { Nuggets } from './js/o-of-options--kacheln';
import { toggleMobileLandingpage} from "./js/of-intro-page--mobile";
import {formatterInputField} from './js/o-formatter'
import {Accordion} from "./js/of-zusammenfassungsthema-head";
import { tableSliderNav } from "./js/table-slider-nav";
import { responsiveCarousel } from "./js/responsive-carousel";
import { animateLayoutcontainer } from "./js/of-layoutcontainer";
// SCSS
import "../mb/mb.scss";
import {NotificationCenter} from "./js/of-notificationcenter";
import {preventToggleOnOpenLightbox} from "./js/o-toggle";

let debuggerOptions = SalientDebugger.getOptions();
debuggerOptions.logMessage = true;
debuggerOptions.logInfo = true;
debuggerOptions.logError = true;
debuggerOptions.logWarnings = true;

SalientDebugger.turnOnDebugger(debuggerOptions);

let initJavascript = () => {
    initCookies();
    responsiveCarousel();
    animateLayoutcontainer();

    if($j('.of-kartemitpraegung')[0]) {
        let creditcard = new CreditCard("lbbw-creditcard-surname", "lbbw-creditcard-name");
        creditcard.listenForInput();
    }

    let selectElements = $j('.materialize .of-options--popup, .materialize .of-selectionfield');
    let checkSelectElements = function () {
        selectElements.find("option").each(function () {
            if($j(this).attr("value") === "WONoSelectionString") {
                this.innerText = "";
            }
        });

        selectElements.each(function () {
            let selectedOption = $j(this).find("select");
            if (selectedOption.val() !== "WONoSelectionString") {
                selectedOption.parent().addClass("is-filled");
                selectedOption.addClass("is-filled");
            } else {
                selectedOption.parent().removeClass("is-filled");
                selectedOption.removeClass("is-filled");
            }
        });
    };

    if (window.innerWidth < 990) {
        if($j(".of-fragengruppe-mobileintro")[0]) {
            toggleMobileLandingpage($j(".of-fragengruppe-mobileintro")[0].id, "of-fragengruppe-mobileintro")
        }
    }

    let kacheln = $j('.of-options--kacheln');
    if(kacheln[0]) {
        kacheln.each((i, item) => {
            if($j(item).find(".of-option-nugget")[0]) {
                new Nuggets($j(item).attr("id"));
            }
        })
    }

    let inputDates = $j('.of-datefield');
    if (inputDates[0]) {
        inputDates.each((i, item) => {
            formatterInputField(item.id, "form-control");
        });
    }

    let accordion = $j('.of-zusammenfassungsthema-head');
    if(accordion[0]) {
        new Accordion('of-zusammenfassungsthema-head', 'of-zusammenfassungsthema-container');
    }

    if(selectElements[0]) {
        checkSelectElements();
        selectElements.on("change", checkSelectElements);
    }

    preventToggleOnOpenLightbox("of-options--ganzezeile");
    $j('[data-toggle="popover"]').popover();

    tableSliderNav()
};

let containerClass= "of-maincolumn";

let fadeout = () => {
    $j(`.${containerClass}`).animate({
        opacity: "0",
        left: "-50px"
    },{
        duration: 200,
        easing: "swing",
        queue: true,
    });
};

let fadeoutBack = () => {
    $j(`.${containerClass}`).animate({
        opacity: "0",
        left: "-50px"
    },{
        duration: 200,
        easing: "swing",
        queue: true,
        complete: fadein
    });
};

let fadein = () => {
    $j(`.${containerClass}`).animate({
        opacity: "1",
        left: "0"
    },{
        duration: 200,
        easing: "swing",
        queue: true,
    });
};

$j( document ).ready(initJavascript);

new NotificationCenter("AppLDFragebogenSeiteOneOfN:updateContainerDidRefresh", initJavascript);
new NotificationCenter("AppLDFragebogenSeiteOneOfN:updateContainerBeforeNext", fadeout);
new NotificationCenter("AppLDFragebogenSeiteOneOfN:updateContainerDidRefresh", fadein);
new NotificationCenter("AppLDFragebogenSeiteOneOfN:updateContainerBeforeBack", fadeoutBack);
