const handleSlide = (tableElement, direction) => {
    const width = tableElement.querySelector("thead th:nth-child(2)").clientWidth + 20;
    const childCount = tableElement.querySelectorAll("thead th").length - 1
    const maxSetting = (childCount - 1) * width * -1

    const tableParts = tableElement.querySelectorAll("thead, tbody")

    const currentSetting = parseInt(tableParts[0].style.transform) || 0
    let newSetting = currentSetting

    if (direction === SWIPES.left) {
        newSetting = currentSetting - width
    }

    if (direction === SWIPES.right) {
        newSetting = currentSetting + width
    }

    if (newSetting > 0) {
        newSetting = 0
    }

    if (newSetting < maxSetting) {
        newSetting = maxSetting
    }

    if (currentSetting !== newSetting) {
        updateIndicator(direction)
    }


    tableParts.forEach(part => {
        part.setAttribute("style", `transform: translateX(${newSetting}px)`)
    })
}

const updateIndicator = (direction) => {
    const activeIndicator = document.querySelector(".table-slider .table-slide-indicator .indicator.active")

    if (direction === SWIPES.right) {
        activeIndicator.previousElementSibling.classList.toggle("active")
    }

    if (direction === SWIPES.left) {
        activeIndicator.nextElementSibling.classList.toggle("active")
    }

    activeIndicator.classList.toggle("active")
}

const SWIPES = {
    left: "left",
    right: "right"
}

const initTouchEvents = (table, callback) => {
    let touchstartX = 0
    let touchendX = 0

    function checkDirection() {
        if(Math.abs(touchendX - touchstartX) < 100) {
            return
        }

        if (touchendX < touchstartX) callback(table, SWIPES.left)
        if (touchendX > touchstartX) callback(table, SWIPES.right)
    }

    const touchstartHandler = e => {
        touchstartX = e.changedTouches[0].screenX
    }

    const touchendHandler = e => {
        touchendX = e.changedTouches[0].screenX
        checkDirection()
    }

    table.addEventListener('touchstart', touchstartHandler)

    table.addEventListener('touchend', touchendHandler)

    let timeout;

    window.addEventListener("resize", () => {
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
        table.removeEventListener('touchstart', touchstartHandler)
        table.removeEventListener('touchend', touchendHandler)

        const tableParts = table.querySelectorAll("thead, tbody")
        tableParts.forEach(part => {
            part.setAttribute("style", "")
        })

        const indicators = table.querySelectorAll(".indicator")
        indicators.forEach((indicator, index) => {
            if (index === 0) {
                indicator.classList.add("active")
            } else {
                indicator.classList.remove("active")
            }
        })

        initTouchEvents(table, callback)
        }, 100)


    })
}




export const tableSliderNav = () => {
    const tableSlider = document.querySelectorAll(".table-slider")

    tableSlider.forEach(table => {
        initTouchEvents(table, handleSlide)
        const showFirstButton = table.querySelector(".table-slider__nav .show-first")
        const showSecondButton = table.querySelector(".table-slider__nav .show-second")

        showFirstButton.addEventListener('click', () => {handleSlide(table,SWIPES.right)})
        showSecondButton.addEventListener('click', () => {handleSlide(table,SWIPES.left)})
    })
}
