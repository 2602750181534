//let Formatter = require("cleave.js");

class Formatter {
    constructor(element, jsonFormat) {
        let fields = document.querySelectorAll(element);

        let numberOfFields = fields.length;
        let i = 0;

        while (i < numberOfFields) {

            fields[i].addEventListener('blur', (e) => {
                let event = new Event('change');
                e.target.dispatchEvent(event);
            });

            fields[i].addEventListener('input', (e) => {
                if(e.inputType === 'insertText' && jsonFormat.delimiter !== "") {
                    let blocks;

                    let formatLength = [];
                    let completedBlocks = [];
                    let j = 0;

                    let currentPattern;
                    let currentBlock;
                    let currentBlockIndex;
                    // init Arrays
                    while (j < jsonFormat.datePattern.length) {
                        completedBlocks.push(false);

                        if (jsonFormat.datePattern[j] === 'd' || jsonFormat.datePattern[j] === 'm') {
                            formatLength.push(2);
                        }

                        if (jsonFormat.datePattern[j] === 'Y') {
                            formatLength.push(4);
                        }

                        j++;
                    }

                    // generate array from input field, if input field already has an input with the delimiter
                    // else create empty array
                    e.target.value = e.target.value.slice(0, -1);

                    if (e.target.value.includes(jsonFormat.delimiter)) {
                        blocks = e.target.value.split(jsonFormat.delimiter);
                        currentBlockIndex = blocks.length-1;
                        currentPattern = jsonFormat.datePattern[currentBlockIndex];
                        currentBlock = blocks[currentBlockIndex];

                        if (blocks[currentBlockIndex] === "") {
                            blocks.pop();
                        }

                        let counter = 0;
                        while (counter < blocks.length) {
                            if(blocks[counter].length === formatLength[counter]) {
                                completedBlocks[counter] = true;
                            }

                            counter++;
                        }
                    } else {
                        currentPattern = jsonFormat.datePattern[0];
                        currentBlock = e.target.value;
                        currentBlockIndex = 0;
                    }

                    let counter = 0;
                    let finished = 0;
                    while (counter < completedBlocks.length) {
                        if (completedBlocks[counter]) {
                            finished++;
                        }
                        counter++;
                    }

                    if (finished === completedBlocks.length) {
                        e.preventDefault();
                    }

                    if (currentPattern === 'd' || currentPattern === 'm' || currentPattern === 'Y') {
                        let appendInput = true;
                        let lastChar = e.data.slice(e.data.length-1);

                        if (currentBlock.length === formatLength[currentBlockIndex] -1 && lastChar !== jsonFormat.delimiter) {
                            e.preventDefault();

                            // check if current block is last block
                            if(currentBlockIndex +1 === formatLength.length) {
                                e.target.value = e.target.value + lastChar;
                            } else {
                                e.target.value = e.target.value + lastChar + jsonFormat.delimiter;
                            }

                            appendInput = false;
                        }

                        if (currentBlock.length === formatLength[currentBlockIndex] -1 && lastChar === jsonFormat.delimiter) {
                            e.preventDefault();
                            let inputLength = e.target.value.length;

                            let sliceEnd;
                            let sliceStart = 0;

                            let counter = 0;
                            sliceEnd = 0;
                            while (counter < completedBlocks.length) {
                                if(completedBlocks[counter]) {
                                    sliceEnd += formatLength[counter];
                                    sliceEnd += jsonFormat.delimiter.length;
                                }

                                counter++;
                            }

                            let newInput = e.target.value.slice(sliceStart, sliceEnd) + "0" + e.target.value.charAt(inputLength -1) + jsonFormat.delimiter;
                            e.target.value = newInput;
                            formatLength[currentBlockIndex] = true;
                            appendInput = false;
                        }

                        if(currentBlock.length >= formatLength[currentBlockIndex]) {
                            appendInput = false;
                        }

                        if(appendInput && lastChar !== jsonFormat.delimiter) {
                            e.target.value += lastChar;
                        }
                    }
                }
            });

            i++;
        }
    }
}

import $j from "jquery";
import SalientDebugger from "../../js/salient-debugger/salientDebugger";

/* eslint-disable */
function formatPlaceholder(dateFormat) {
    let placeholder = "";
    let format = dateFormat.datePattern;
    format.forEach((element, i) => {
        switch (element) {
            case 'd':
                placeholder += "TT";
                break;
            case 'm':
                placeholder += "MM";
                break;
            case 'Y':
                placeholder += "YYYY";
                break;
            case 'y':
                placeholder += "YY";
                break;
        }

        if (i + 1 < format.length) {
            placeholder += dateFormat.delimiter
        }
    });

    return placeholder;
}

/* eslint-enable */

export function formatterInputField(id, element) {
    let inputFieldFormat = $j("#" + id).data("format");
    let dateDelimiter = "";

    let doNotFormatRegEx = /((%%|%A|%a|%b|%B|%c|%e|%F|%H|%h|%I|%j|%M|%p|%S|%w|%x|%X|%z|%Z)+)/;

    if (inputFieldFormat.match(doNotFormatRegEx) === null) {
        SalientDebugger.messageInfo("The field with id " + id + " is formatted");
        let dateFormat = [];
        let regex = /(([a-z])?([A-Z])?){1}/;

        if (inputFieldFormat.search('%%') === -1) {
            inputFieldFormat = inputFieldFormat.split("%");
            inputFieldFormat.forEach((item) => {
                if (item !== "") {
                    let regReturn = item.match(regex);
                    if (regReturn[0] !== null) {
                        dateFormat.push(regReturn[0]);
                        let indexChar = regReturn["input"].indexOf(regReturn[0]);

                        if (regReturn["input"].length > 1) {
                            dateDelimiter = regReturn["input"].slice(indexChar + 1);
                        }
                    }
                }
            });
        }

        inputFieldFormat = dateFormat;

        let jsonFormat = {
            date: true,
            datePattern: inputFieldFormat,
            delimiter: dateDelimiter
        };

        SalientDebugger.messageInfo("CleaveJS options for initialization");
        SalientDebugger.messageLog(jsonFormat);

        new Formatter("#" + id + " ." + element, jsonFormat);

        /*
        $j(`#${id} .${element}`).focus(() => {

            if($j(`#${id} .${element}`).val() === '') {
                // $j(`#${id} .${element}`).attr("placeholder", formatPlaceholder(jsonFormat));
                $j(`#${id} .${element}`).val(formatPlaceholder(jsonFormat));
            }
        });


        $j(`#${id} .${element}`).blur(() => {
            $j(`#${id} .${element}`).removeAttr("placeholder");
        });
         */
    } else {
        SalientDebugger.messageInfo("The field with id " + id + " is not formatted");
    }
}
